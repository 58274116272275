
.container {
  height: 90vh;
  }

.container::before{
    content: "";
    position: absolute;
    inset: 0;
    background-image: url(BG\ Image\ PNG.png);
    background-size: cover;
    background-position: center;
    z-index: -1;
}
  
.heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    padding-top: 250px;
    text-align: center;
    color: #EAEAEA; 
}
  

.content {
    display: flex;
    flex-direction: column; /* Stack the sections vertically */
    align-items: center; /* Center align the sections horizontally */
    gap: 20px; /* Add spacing between sections */
    justify-content: center; /* Center vertically */
}

.section {
    text-align: center; /* Center align the text and buttons */
    max-width: 600px; /* Optional: limit the width for readability */
}
  
.text {
    margin-bottom: 10px;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #EAEAEA;
    text-align: center;
}
  
.link {
    margin: 10px auto 0; /* Center align the button and add spacing */
    font-size: 1.5rem;
    color: #EAEAEA; 
    text-decoration: none;
    background-color: #9D2933; 
    padding: 10px 20px; /* Add padding for better click area */
    display: inline-block; /* Keep the button size appropriate */
    transition: background-color 0.5s ease, color 0.5s ease; 
}

.link.secondary{
    background-color: #EAEAEA;
    color: #232224;
}

.link:hover {
    background-color: #E63946; 
    color: #EAEAEA; 
}

@media (max-width: 768px) {
    .container {
        height: auto; 
    }

    .dropdown-menu-container {
        background-color: transparent; /* Set background to black */
      }

    .heading {
        font-size: 2rem;
        padding-top: 200px;
        margin-bottom: 15px;
    }

    .content {
        flex-direction: column;
        gap: 50px;
        align-items: center;
    }

    .section {
        max-width: 90%;
        text-align: center;
        align-items: center;
    }

    .text {
        font-size: 1.2rem;
        line-height: 1.4;
    }

    .link {
        font-size: 1.2rem;
        padding: 10px 15px;
        align-items: left;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 1.8rem;
        padding-top: 150px;
    }

    .content {
        gap: 50px;
    }

    .text {
        font-size: 1rem;
    }

    .link {
        font-size: 1rem;
        padding: 8px 12px;
    }
}