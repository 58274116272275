/* Navbar container styling */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: transparent; 
    padding: 20px 0; /* Add padding to give some space */
    z-index: 1000; /* Keep the navbar on top */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center all child elements horizontally */
    margin-bottom: 15px;
    transition: top 0.3s ease
}
  
  /* Style the logo and center it */
.logo {
    height: 80px; /* Adjust logo size */
    margin-bottom: 20px; /* Space between logo and links */
}
  
  /* Center the links below the logo */
.original-unorganized-list  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: center; /* Center the buttons vertically */
}

.original-list-item.transparent a{
  color: #EAEAEA;
}
  
  /* Style each list item */
.original-list-item {
    margin: 0 15px; /* Space between buttons */
}
  
  /* Remove default styling from links and make them white */
.navbar a {
    text-decoration: none;
    font-size: 22px; 
    padding-bottom: 5px;
}
  
.navbar a.active {
    border-bottom: 1px solid #9D2933;
    color: #9D2933;
}
  
  /* Style the logout button */
.navbar .logout-button {
    background-color: black; /* Match background color of the navbar */
    border: none; /* Remove default button border */
    color: white; /* Set button text color to white */
    font-size: 18px; /* Adjust font size */
    cursor: pointer; /* Make the cursor a pointer */
    padding: 10px 20px; /* Add padding to the button */
    font-weight: bold; /* Make the button text bold */
}
  
.navbar a:hover, .navbar .logout-button:hover {
    color: #9D2933 /* Change the color on hover */
}

.back-to-top.show {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Enable interaction */
}

.back-to-top:hover {
  background-color: gray; /* Darker blue on hover */
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.back-to-top:active {
  transform: scale(0.95); /* Slight shrink effect on click */
}

.no-underline {
  text-decoration: none; /* Remove underline */
}

/* Hide burger menu button and dropdown by default */
.burger-menu {
  display: none;
}

/* Dropdown Menu Styles */
@media screen and (max-width: 768px) {
  .burger-menu {
    display: block;
    font-size: 24px;
    background: none;
    border: none;
    color: #242223;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1001; /* Ensure it is above other elements */
  }

  .burger-menu.original.transparent{
    color: #242223;
  }

  .burger-menu.transparent {
    color: #EAEAEA;
  }

  .navbar {
    background-color: #EAEAEA;
    transition: background-color 0.3s ease;
  }
  
  .navbar.transparent {
    background-color: transparent !important;
  }

  .dropdown-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1000;
    transition: top 0.3s ease, background-color 0.3s ease;
    padding-top: 200px;
    padding: 20px;
  }

  .dropdown-menu-container.open {
    padding-top: 100px;
    display: flex;
    background-color: #eaeaea;
  }

.dropdown-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* Space below the logo */
}

.dropdown-logo {
  height: 60px; /* Adjust the logo size */
  margin-right: 20px; /* Add some space after the logo */
}

.dropdown-menu-unorganized-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.dropdown-menu-list-item {
  margin: 10px 0; /* Space between menu items */
}

.dropdown-menu-list-item a {
  text-decoration: none;
  font-size: 24px;
  color: #242223;
  transition: color 0.3s ease;
}

.dropdown-menu-list-item a.active {
  border-bottom: 2px solid #9D2933;
  color: #9D2933;
}

.dropdown-menu-list-item a:hover {
  color: lightgray;
}

.dropdown-logout-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  margin-top: 20px;
}

.dropdown-logout-button:hover {
  color: lightgray;
}

.logo{
height: 50px;
margin: -10px;
}
.original-unorganized-list{
  display: none;
}
}

