.create-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height to center vertically */
  }
  
  .create-form form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .create-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-form label {
    display: block;
    margin-bottom: 15px;
  }
  
  .create-form label span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .create-form input[type="text"],
  .create-form textarea,
  .create-form input[type="file"] {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .create-form textarea {
    height: 100px;
    resize: none;
  }
  
  .create-form .btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .create-form .btn:disabled {
    background-color: #c0c0c0;
  }
  
  .create-form .error {
    color: red;
    margin-top: 10px;
    text-align: center;
  }
  