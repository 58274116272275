.values-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    max-width: 100%;
    padding-top: 250px;
}
  

.values-content {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
.values-primary-section {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}
  
.values-title-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 20px;
  }
  
.values-primary-header {
    font-size: 2rem;
    margin: 0;
}
  
.values-text-container {
    flex: 2;
    display: flex;
    align-items: flex-start;
}
  
.values-primary-text {
    font-size: 1.5rem;
    line-height: 1.6;
    margin: 0;
}
  
.values-secondary-header {
    font-size: 1.5rem;
    margin-top: 20px;
}
  
.values-secondary-text {
    font-size: 1.5rem;
    line-height: 1.6;
    margin-top: 10px;
}
  
.values-button {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    background-color: #9D2933;
    border: none;
    cursor: pointer;
    transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition */
}
  
.values-button a {
    color: white;
    text-decoration: none;
    flex-grow: 1;
    font-size: 1.3rem;
    padding: 10px;
    color: #EAEAEA;
}
  
.values-button:hover {
    background-color: #E63946;
}

/* Responsive Design */
@media (max-width: 768px) {
  .values-container {
      padding: 15px; 
      padding-top: 150px; 
      padding-bottom: 75px;
  }

  .values-content {
      max-width: 90%; 
      align-items: center; 
  }

  .values-primary-section {
      flex-direction: column; 
      align-items: center;
  }

  .values-title-container {
      padding-right: 0; 
      text-align: left; 
  }

  .values-primary-header {
      font-size: 1.8rem; 
  }

  .values-text-container {
      justify-content: center;
      text-align: left; 
  }

  .values-primary-text {
      font-size: 1.3rem; 
  }

  .values-secondary-header {
      font-size: 1.3rem; 
      text-align: left; 
  }

  .values-secondary-text {
      font-size: 1.2rem; 
  }

  .values-button {
      margin: 20px auto 40px auto; 
      width: 100%; 
      max-width: 300px; 
      justify-content: center; 
  }

  .values-button a {
      font-size: 1.2rem; 
  }
}

@media (max-width: 480px) {
  .values-container {
      padding: 10px; 
      padding-top: 100px; 
      padding-bottom: 75px;
  }

  .values-primary-header {
      font-size: 1.5rem; 
  }

  .values-primary-text,
  .values-secondary-text {
      font-size: 1rem; 
  }

  .values-button {
      padding: 10px;
  }

  .values-button a {
      font-size: 1rem;
  }
}