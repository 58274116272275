.about-container {
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
}
  
.about-title {
    font-size: 2rem;
    margin-bottom: 10px;
}
  
.about-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    margin: 20px 0;
}
  
.about-name {
    font-size: 1.5rem;
    font-weight: bold;
}
  
.about-text {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .about-container {
        padding-top: 100px; 
        text-align: left; 
        max-width: 90%; 
        padding-bottom: 75px;
    }

    .about-title {
        font-size: 1.75rem; 
    }

    .about-image {
        width: 200px; 
        height: 200px;
        margin: 15px 0; 
    }

    .about-name {
        font-size: 1.25rem; 
    }

    .about-text {
        font-size: 1.2rem; 
    }

}

@media (max-width: 480px) {
    .about-container {
        padding-top: 80px; 
        padding-bottom: 75px;
    }

    .about-title {
        font-size: 1.5rem; 
    }

    .about-image {
        width: 150px;
        height: 150px; 
    }

    .about-name,
    .about-text {
        font-size: 1rem;
    }
}