.container {
  padding-top: 200px;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.backButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.hollowArrow {
  width: 50px; /* Adjust size */
  height: 50px;
  stroke: #000; /* Change to your preferred color */
  transition: stroke 0.3s ease;
}

.backButton:hover .hollowArrow {
  stroke: #9D2933; /* Color on hover */
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.coverPhotoWrapper {
  width: 1000px;
  height: 565px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto; /* Horizontally centers the container */
}

.coverPhoto {
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 30px;
}

.detailsWrapper {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #242223;
  justify-content: center;
  align-items: center; 
}

.details {
  color: white;
  width: 1000px;
  margin: 20px auto;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.imageGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align images horizontally */
  gap: 20px; /* Space between images */
  max-width: 80%;
  margin: 20px auto; /* Center the gallery within the page */
}

.gridItem {
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.galleryImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  cursor: zoom-in;
}

.gridItem:hover .galleryImage {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.gridItem::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease, background-color 0.5s ease;
  pointer-events: none;
}

.gridItem:hover::before {
  transform: translate(-50%, -50%) scale(1);
  background: rgba(255, 255, 255, 0.5);
}

.gridItem:hover .galleryImage {
  transform: scale(1.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.removeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.removeButton:hover {
  transform: scale(1.1);
  background-color: rgba(255, 0, 0, 1);
}

.customFileUpload {
  display: inline-block;
  padding: 12px 24px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 10px;
}

.customFileUpload:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.imageUploadInput {
  display: none;
}

.addImagesButton {
  padding: 12px 24px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.addImagesButton:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.previewItem {
  width: 100px;
  height: 100px;
  position: relative;
  border: 2px solid #007bff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.removePreviewButton {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.removePreviewButton:hover {
  background-color: rgba(255, 0, 0, 1);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modalActions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.confirmButton {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.confirmButton:hover {
  background-color: #c9302c;
}

.cancelButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cancelButton:hover {
  background-color: #5a6268;
}

.expandedImageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expandedImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editPosition {
  position: absolute; /* Position it outside the grid item */
  bottom: 5px; /* Adjust placement below the grid item */
  left: 50%;
  transform: translateX(-50%); /* Center it horizontally */
  background-color: rgba(255, 255, 255, 0.9); /* Optional: Add background for visibility */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.editPosition label {
  font-size: 14px;
  color: #000;
}

.positionInput {
  width: 50px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
}

.expandedImage {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
}

.closeButton:hover {
  transform: scale(1.2);
}

.leftArrow,
.rightArrow {
  position: fixed;
  top: 50%;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  transition: transform 0.2s ease;
  z-index: 1001;
}

.leftArrow {
  left: 10px;
  transform: translateY(-50%);
}

.rightArrow {
  right: 10px;
  transform: translateY(-50%);
}

.leftArrow:hover,
.rightArrow:hover {
  transform: scale(1.2) translateY(-50%);
}

/* General Container Adjustments for Small Screens */
@media (max-width: 768px) {
  .container {
    padding-top: 150px;
  }

  .leftArrow,
  .rightArrow {
    top: 80%;
  }

  .title {
    font-size: 1.5rem; 
  }

  .backButton{
    display: none;
  }

  .details {
    width: 90%; 

    font-size: 1rem; 
    line-height: 1.4;
  }

  .coverPhotoWrapper {
    width: 90%; 
    height: auto; 
    margin: 0 auto; 
  }

  .coverPhoto {
    max-width: 100%; 
    height: auto; 
  }

  .imageGallery {
    gap: 5px; 
  }

  .gridItem {
    width: 100%; 
    height: 250px;
  }
}

@media (max-width: 480px) {
  .container {
    padding-top: 100px;
  }

  .title {
    font-size: 1.2rem; 
  }

  .backButton{
    display: none;
  }

  .details {
    width: 95%; 
    font-size: 1rem; 
  }

  .coverPhotoWrapper {
    width: 100%; 
    height: auto;
  }

  .coverPhoto {
    max-width: 100%;
    height: auto;
  }

  .imageGallery {
    max-width: 100%;
    gap: 5px;
    margin-top: 30px;
  }

  .gridItem {
    width: 100%;
    height: 250px;
  }
}
