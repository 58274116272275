.contactContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    gap: 2rem;
    padding: 2rem;

    padding-top: 250px;
}

.contactInfo {
    max-width: 800px; /* Limit the width */
    width: 100%;
    text-align: center; /* Center the text */
    margin-bottom: 2rem;
}

.contactInfo h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.contactInfo p {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.contactInfo hr {
    margin: 1rem auto;
    border: none;
    border-top: 1px solid #242223;
    width: 50%; /* Center the horizontal line */
}

.contactInfo address {
    font-style: normal;
    margin-bottom: 1rem;
}

.social {
    margin-top: 1rem;
}

.social a {
    text-decoration: none;
    font-size: 1.5rem; 
    transition: color 0.3s;
    margin: 0 0.5rem; /* Space between icons */
}

.social a:hover {
    color: #e1306c; 
}

.contactForm {
    max-width: 800px;
    width: 100%;
    background-color: #EAEAEA;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputGroup {
    display: flex;
    gap: 3rem;
    width: 100%;
}

.inputGroup div {
    flex: 1;
}

.contactForm input,
.contactForm textarea {
    width: 100%; /* Ensure inputs and textarea span the full width */
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #242223;
    background-color: #EAEAEA;
    color: #242223;
    box-sizing: border-box; /* Include padding and border in the width */
}

.contactForm label {
    display: block;
    margin-bottom: 0.5rem; /* Space between label and input */
    font-size: 0.9rem;
    width: 100%; /* Ensure the label takes full width */
    text-align: left; /* Align labels to the left */
}

.contactForm .fullWidth {
    width: 100%; /* Full width of the parent container */
    margin-top: 1rem;
}

.contactForm .fullWidth input,
.contactForm .fullWidth textarea {
    flex: 1; /* Make inputs span the full width of the container */
    padding: 0.8rem;
}

.contactForm textarea {
    resize: none;
    height: 150px;
}

.submitButton {
    width: 100%; /* Match width of input fields */
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #EAEAEA;
    background-color: #9D2933;
    border: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.5s ease, color 0.5s ease;
    box-sizing: border-box; /* Include padding and border in the width */
    margin-top: 1rem; /* Space above the button */
}

.submitButton:hover {
    background-color: #E63946;
    color: #EAEAEA;
}

.contactForm .buttonContainer {
    width: 100%; /* Ensures the button container spans full width */
    display: flex;
    justify-content: center; /* Center-aligns the button */
    margin-top: 1rem; /* Adds space above the button */
}



@media (max-width: 768px) {
  .contactContainer {
      padding-top: 150px;
      padding-bottom: 100px;
  }

  .contactInfo h2 {
      font-size: 2rem;
  }

  .contactInfo p {
      font-size: 0.9rem;
  }

  .social a {
      font-size: 1.2rem;
  }

  .contactForm {
      padding: 1rem;
  }
}

@media (max-width: 480px) {
  .contactContainer {
      padding: 1rem;
      padding-top: 100px;
      padding-bottom: 75px;
  }

  .contactInfo h2 {
      font-size: 1.8rem;
  }

  .contactForm input,
  .contactForm textarea {
      font-size: 0.9rem;
  }

  .submitButton {
      padding: 0.7rem;
      font-size: 0.9rem;
  }

  .social a {
      font-size: 1rem;
  }
}
