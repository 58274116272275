/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #eaeaea;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,p,a{
  color: #242223;
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Background of the scrollbar track */
::-webkit-scrollbar-track {
  background: #242223;
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #c5c2c2;
}

/* Scrollbar handle hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* Scrollbar buttons (arrows) */
::-webkit-scrollbar-button {
  background-color: #242223;
  border: none;
}

/* Up arrow */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-color: #c5c2c2;
}

/* Down arrow */
::-webkit-scrollbar-button:single-button:vertical:increment {
  background-color: #c5c2c2;
}

/* Remove custom content to keep original arrow shapes */
::-webkit-scrollbar-button:single-button {
  mask: none;
  -webkit-mask: none;
}


/*  #EAEAEA (White), 
    #242223 (Black), 
    #9D2933 (Japanese Red),
    #E63946 (Highlight Red) */