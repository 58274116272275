/* Center the form in the middle of the page */
.auth-form {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Styling for form elements */
  .auth-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .auth-form label {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .auth-form span {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .auth-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .auth-form input:focus {
    border-color: #007BFF;
    outline: none;
  }
  
  /* Styling for buttons */
  .auth-form .btn {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .auth-form .btn:hover {
    background-color: #0056b3;
  }
  
  .auth-form .btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  /* Error message styling */
  .auth-form .error {
    margin-top: 10px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  