.project-list {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 250px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.project-card {
  position: relative;
  width: 50px;
  height: 450px;
  flex-shrink: 0;
  overflow: hidden;
  transition: width 0.6s ease-in-out,;
}

.project-card:hover {
  width: 450px;
  height: 450px;
}

.project-image {
  width: 100%;
  height: 450px;
  object-fit: cover;
  transition: transform 0.6s ease-in-out, filter 0.6s ease-in-out; 
}

.project-card:hover .project-image {
  transform: scale(1);
  filter: blur(5px);
}

.project-name {
  position: absolute;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%); 
  font-size: 24px;
  text-align: center;
  z-index: 10;
  display: none;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s ease-in-out;
  color: white;
}

.project-card:hover .project-name {
  display: block;
  z-index: 100;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 4;
}

/* Overlay for confirmation modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content p {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-content button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-content button:first-child {
  background-color: red;
  color: white;
  border: none;
}

.modal-content button:last-child {
  background-color: gray;
  color: white;
  border: none;
}

/* Edit input fields */
.edit-position {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.edit-position label {
  font-size: 14px;
  color: white;
}

.position-input {
  width: 50px;
  padding: 4px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

.position-input::-webkit-inner-spin-button, 
.position-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    width: 100%;
  }

  .project-list {
    margin-top: 150px;
    gap: 10px;
    padding: 10px;
    padding-top: 100px;
  }

  .project-card {
    display: flex;
    flex-direction: column; /* Stack project image and name vertically */
    align-items: center; /* Center-align content */
  }

  .project-card:hover {
    width: 300px;
    height: 300px;
  }

  .project-name {
    display: block; /* Always visible on smaller screens */
    font-size: 30px;
    transform: rotate(270deg); /* Rotate text to display vertically */
    transform-origin: left center; /* Rotate around the left edge */
    margin-top: 105px; /* Remove top margin */
    white-space: nowrap; /* Prevent wrapping of text */
  }

  .delete-button {
    padding: 3px 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html, body {
    overflow-x: hidden;
    width: 100%;
  }

  .project-list {
    margin-top: 100px;
    gap: 8px;
  }

  .project-card {
    width: 30px;
    height: 250px;
  }

  .project-card:hover {
    width: 250px;
    height: 250px;
  }

  .project-name {
    font-size: 16px;
  }

  .delete-button {
    padding: 2px 6px;
    font-size: 10px;
  }

  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .modal-content p {
    font-size: 16px;
  }

  .modal-content button {
    font-size: 14px;
    padding: 8px 15px;
  }
}